import React, { useState, useRef } from "react"
import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavBar from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"
import SmallImg from "../../../components/Image/SmallImg"

import {
  Title,
  Select,
  Input,
  Button,
  Field,
  Control,
  Columns,
  Column,
  StaticButton,
} from "../../../StyleComponents/styles"
import { FlexDiv } from "../../../StyleComponents/pagesStyle"

const navRoadArray = [
  { name: "Calculator", link: "/calculator/" },
  {
    name: "Tax Cost Calculator",
    link: "/tax-cost-calculator/",
  },
]
const seeAlsoArray = [
  "/fuel-consumption-calculator",
  "/mortgage-loan-calculator",
  "/percentage-calculator",
  "/shopping-calculator",
  "/age-calculation",
]

function TaxCostCalculator(props) {
  let [Amount, setAmount] = useState("")
  let [TaxInOut, setTaxInOut] = useState("in")
  let [TaxRate, setTaxRate] = useState("")
  let [reSult, setreSult] = useState("")
  let unitSelector = useRef("")

  function AmountC(e) {
    setAmount(e.target.value)
  }
  function TaxInOutC(e) {
    setTaxInOut(e.target.value)
  }
  function TaxRateC(e) {
    setTaxRate(e.target.value)
  }

  function calculator() {
    Amount = Number(Amount)
    TaxRate = Number(TaxRate)
    let unit = unitSelector.current.value
    if (Amount === "" || Amount <= 0) {
      setreSult(<strong>Please Enter Amount !</strong>)
    } else if (TaxRate === "" || TaxRate <= 0) {
      setreSult(<strong>Please Enter Tax Rate !</strong>)
    } else {
      if (TaxInOut === "in") {
        let resultOfTax = (TaxRate / 100) * Amount
        let includingTax = Amount + resultOfTax
        setreSult(
          <span>
            Amount (excluding tax) : {Amount} {unit}
            <br />
            Gross Amount (including tax) : {includingTax.toFixed(3)} {unit}
            <br />
            Tax ({TaxRate}%) is : {resultOfTax.toFixed(3)} {unit}
            <br />
          </span>
        )
      } else if (TaxInOut === "out") {
        let resultOfTax = (Amount * TaxRate) / (100 + TaxRate)
        let includingTax = Amount - resultOfTax

        setreSult(
          <span>
            Amount (excluding tax) : {includingTax.toFixed(3)} {unit}
            <br />
            Amount (including tax) : {Amount} {unit}
            <br />
            Tax ({TaxRate}%) is : {resultOfTax.toFixed(3)} {unit}
            <br />
          </span>
        )
      }
    }
  }

  return (
    <Layout location={props.location}>
      <SEO
        title="Tax Cost Calculator"
        description="Tax Cost Calculator, Simple Calculate of Amount Tax exclusive, or Amount tax inclusive, calculate tax cost percentage."
        keywords={[
          "calculate tax, calculate tax Exclusive, calculate tax inclusive, tax cost calculation,VAT cost inclusive calculation,Gst Calculator, tax cost, tax Exclusive cost, tax cost inclusive, tax percentage calculator, percentage tax cost calculator, percentage tax calculation.",
        ]}
      />
      <NavBar listPages={navRoadArray} />
      <div className="container">
        <Title>Tax Cost Calculator</Title>
        <Columns>
          <Column>
            <Field>
              <Control>
                <StaticButton>Amount</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  value={Amount}
                  onChange={AmountC}
                  type="number"
                  placeholder="Bill Amount"
                />
              </Control>
              <Control>
                <Select borderRadius="0">
                  <select
                    className="staticSelect"
                    aria-label="Tax"
                    value={TaxInOut}
                    onBlur={TaxInOutC}
                    onChange={TaxInOutC}
                  >
                    <option value="in">Tax Exclusive</option>
                    <option value="out">Tax inclusive</option>
                  </select>
                </Select>
              </Control>
              <Control>
                <Select>
                  <select
                    className="staticSelect"
                    aria-label="unitSelector"
                    ref={unitSelector}
                  >
                    <option value="&#36;">&#36;</option>
                    <option value="&#162;">&#162;</option>
                    <option value="&#163;">&#163;</option>
                    <option value="&#165;">&#165;</option>
                    <option value="&#8355;">&#8355;</option>
                    <option value="&#8356;">&#8356;</option>
                    <option value="&euro;">&euro;</option>
                  </select>
                </Select>
              </Control>
            </Field>

            <Field>
              <Control>
                <StaticButton>Tax Rate</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  value={TaxRate}
                  onChange={TaxRateC}
                  type="number"
                  placeholder="Tax rate (%)"
                />
              </Control>
              <Control>
                <StaticButton
                  bottomRightRadius="4px"
                  topRightRadius="4px"
                  bottomLeftRadius="0"
                  TopLeftRadius="0"
                >
                  &#37;
                </StaticButton>
              </Control>
            </Field>

            <Button
              borderColor="#1da1f2"
              color="#1da1f2"
              hoverColor="white"
              hoverBorderColor="#1da1f2"
              hoverBackgroundColor="#1da1f2"
              onClick={calculator}
            >
              Calculate
            </Button>
            <p className="h5 mt-3">{reSult}</p>
          </Column>

          <Column>
            <FlexDiv maxWidth="300px" margin="auto">
              <SmallImg filename="tax.png" alt="tax calculator" />
            </FlexDiv>
          </Column>
        </Columns>
        <br />
        {/* <div className="container" style={{ textAlign: "left" }}>
          <h1 className="title fas mycolor">Tax Excluded</h1>
          <p>
            The excluded tax amount will be each time the same, example:
            excluded tax amount is 100$, And the taxes will be calculated ere
            the payments during the checkout and added to the excluded tax price
            depending on your client evidence, your tax threshold, and the
            quality of the item sold. If selling tax/VAT/GST is due, calculated,
            and add it on top of the 100$. example: Italian customers: VAT is
            22%. the calculation is 100$ × 1,22 = 122$, this amount is the price
            in which you will sell your service/product.
          </p>
        </div>

        <br />
        <div className="container" style={{ textAlign: "left" }}>
          <h1 className="title fas mycolor">Tax Included</h1>
          <p>
            A Tax Included means that you sell a service or product at a similar
            price for all buyers at all times in all countries. For example, if
            you market an object for 100€ in your country, it will be sell for
            the same price in all countries. And the tax calculated after the
            amount depending on your customer evidence, your tax threshold. If
            sales tax/VAT/GST is due, will deduct the tax amount from the 100€
            you sold.
            <br />
            <strong>
              Calculation when the price is inclusive : Tax amount = Value
              inclusive of tax (Amount) × tax rate ÷ (100 + tax rate)
            </strong>
          </p>
        </div> */}
        <SeeAlso seeAlsoList={seeAlsoArray} />

        <br />
      </div>
    </Layout>
  )
}

export default TaxCostCalculator
